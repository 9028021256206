<template>
  <PageLayout>
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-one-third">
            <div class="has-padding">
              <div class="box">
                <ForgotPassword
                  :backFunc="backAction"
                  @sentSuccessfully="gotoSetNewPassword"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </PageLayout>
</template>

<script>
const PageLayout = () => import(/* webpackChunkName: "my-account-forgotPassword" */ '../../../components/MainSiteElements/Frontend/PageLayout.vue')
const ForgotPassword = () => import(/* webpackChunkName: "my-account-forgotPassword" */ '@/components/AccountControls/ForgotPassword')

export default {
  name: 'view-forgot-password',

  components: {
    PageLayout,
    ForgotPassword
  },

  methods: {
    backAction() {
      this.$router.go(-1)
    },

    gotoSetNewPassword() {
      this.$router.push({ name: 'my-account-setpassword' })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>